import React from "react";

const OrderDetails = ({ order }) => {
  // Hardcoded dates to simulate the status updates
  const createdDate = new Date(order.created_at);
  const pickedUpDate = new Date(createdDate.getTime() + 1 * 24 * 60 * 60 * 1000); // +1 day
  const departedFromDate = new Date(createdDate.getTime() + 2 * 24 * 60 * 60 * 1000); // +2 days
  const firstDeliveryAttemptDate = new Date(createdDate.getTime() + 3 * 24 * 60 * 60 * 1000); // +3 days
  const secondDeliveryAttemptDate = new Date(createdDate.getTime() + 4 * 24 * 60 * 60 * 1000); // +4 days
  const heldAtFacilityDate = new Date(createdDate.getTime() + 5 * 24 * 60 * 60 * 1000); // +5 days
  const deliveredDate = new Date(createdDate.getTime() + 5 * 24 * 60 * 60 * 1000); // +5 days

  // Function to format date to "27 Aug 2024"
  const formatDate = (date) => {
    return date.toLocaleDateString("en-GB", {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    }).replace(/(\d{2}) ([A-Za-z]{3}) (\d{4})/, "$1 $2 $3");
  };

  return (
    <div className="order-details">
      <h2>Shipping Details</h2>
      <p>Order Number: <strong>{order.order_number}</strong></p>
      <p>Customer Email: <strong>{order.customer.email}</strong></p>
      <div className="status-line">
        <div className="status-item completed">
          <span className="order_detail_date">{formatDate(createdDate)}</span>
          <span className="order_detail_status">New York, NY--Shipment created</span>
        </div>
        <div className={`status-item ${new Date() >= pickedUpDate ? 'completed' : ''}`}>
          <span className="order_detail_date">{formatDate(pickedUpDate)}</span>
          <span className="order_detail_status">New York, NY--Package picked up</span>
        </div>
        <div className={`status-item ${new Date() >= departedFromDate ? 'completed' : ''}`}>
          <span className="order_detail_date">{formatDate(departedFromDate)}</span>
          <span className="order_detail_status">New York, NY--Departed from facility</span>
        </div>
        <div className={`status-item ${new Date() >= firstDeliveryAttemptDate ? 'completed' : ''}`}>
          <span className="order_detail_date">{formatDate(firstDeliveryAttemptDate)}</span>
          <span className="order_detail_status">New York, NY--Delivery attempted, recipient unavailable</span>
        </div>
        <div className={`status-item ${new Date() >= secondDeliveryAttemptDate ? 'completed' : ''}`}>
          <span className="order_detail_date">{formatDate(secondDeliveryAttemptDate)}</span>
          <span className="order_detail_status">New York, NY--Delivery attempted, recipient unavailable</span>
        </div>
        <div className={`status-item ${new Date() >= heldAtFacilityDate ? 'completed' : ''}`}>
          <span className="order_detail_date">{formatDate(heldAtFacilityDate)}</span>
          <span className="order_detail_status">New York, NY--Package held at local facility</span>
        </div>
        <div className={`status-item ${new Date() >= deliveredDate ? 'completed' : ''}`}>
          <span className="order_detail_date">{formatDate(deliveredDate)}</span>
          <span className="order_detail_status">New York, NY--Package delivered</span>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
