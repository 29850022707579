const OrderDetails = ({ order }) => {
  const createdDate = new Date(order.created_at);

  // Define the statuses with days offset and location/status text
  const statuses = [
    { daysOffset: 0, location: "New York, NY, USA", status: "Shipment created" },
    { daysOffset: 1, location: "New York, NY, USA", status: "Package picked up" },
    { daysOffset: 2, location: "New York, NY, USA", status: "Departed from facility" },
    { daysOffset: 3, location: "JFK Airport, NY, USA", status: "Arrived at airport" },
    { daysOffset: 4, location: "JFK Airport, NY, USA", status: "Customs clearance initiated" },
    { daysOffset: 4, location: "JFK Airport, NY, USA", status: "Customs clearance completed" },
    { daysOffset: 5, location: "JFK Airport, NY, USA", status: "Departed" },
    { daysOffset: 6, location: "London, UK", status: "Arrived at intermediate airport" },
    { daysOffset: 7, location: "London, UK", status: "Departed from intermediate airport" },
    { daysOffset: 8, location: "Paris, France", status: "Arrived at destination airport" },
    { daysOffset: 9, location: "Paris, France", status: "Customs clearance initiated" },
    { daysOffset: 10, location: "Paris, France", status: "Customs clearance completed" },
    { daysOffset: 11, location: "Lyon, France", status: "Package sorted" },
    { daysOffset: 12, location: "Lyon, France", status: "Transported to land logistics facility" },
    { daysOffset: 13, location: "Marseille, France", status: "Package held at local facility" },
    { daysOffset: 14, location: "Marseille, France", status: "Out for delivery again" },
  ];

  // Function to format date to "27 Aug 2024"
  const formatDate = (date) => {
    return date.toLocaleDateString("en-GB", {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });
  };

  // Generate statuses with dynamic dates
  const statusUpdates = statuses.map((status) => {
    const statusDate = new Date(createdDate.getTime() + status.daysOffset * 24 * 60 * 60 * 1000);
    return { ...status, date: statusDate };
  });

  return (
    <div className="order-details">
      <h2>Shipping Details</h2>
      <p>Order Number: <strong>{order.order_number}</strong></p>
      <p>Customer Email: <strong>{order.customer.email}</strong></p>
      <div className="status-line">
        {statusUpdates.map((status, index) => (
          <div key={index} className={`status-item ${new Date() >= status.date ? 'completed' : ''}`}>
            <span className="order_detail_date">{formatDate(status.date)}</span>
            <span className="order_detail_status">{status.location} - {status.status}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderDetails;
