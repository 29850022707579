import React, { useState } from "react";
import OrderDetails from "./components/OrderDetails";
import "./App.css";

function App() {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderNumber, setOrderNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleTrackOrder = () => {
    if (!orderNumber.trim()) return; // Do nothing if order number is empty

    setLoading(true);
    setError(null);
    setSelectedOrder(null); // Clear previous order details
    // Fetch the order from your backend API based on the order number
    fetch(`https://backend.waydevelopers.com/api/orders/${orderNumber.trim()}`)
      .then((res) => {
        if (!res.ok) throw new Error("Order not found");
        return res.json();
      })
      .then((data) => {
        setSelectedOrder(data); // Set the fetched order as the selected order
      })
      .catch((error) => {
        setError(error.message); // Show an error if order is not found
      })
      .finally(() => {
        setLoading(false); // Re-enable the button once the fetch is complete
      });
  };

  return (
    <div className="app-container">
      <h1>Order Tracking</h1>
      
      <div className="order-tracking-form">
        <input
          type="text"
          placeholder="Enter Order Number"
          value={orderNumber}
          onChange={(e) => setOrderNumber(e.target.value)}
        />
        <button onClick={handleTrackOrder} disabled={loading}>
          {loading ? "Loading..." : "Track Order"}
        </button>
      </div>

      {error && <p className="error-message">{error}</p>}
      {selectedOrder ? (
        <OrderDetails order={selectedOrder} />
      ) : (
        !loading && <p>Enter an order number to view details.</p>
      )}
    </div>
  );
}

export default App;
